import React from "react"
import { Link } from "gatsby"
import Layout from "./layout"
import SEO from "./seo"
import Img from 'gatsby-image'

import styles from '../styles/insights.module.css'


function BrowseBlogPosts({ pageContext }) {

    const { groupedPosts, group, page } = pageContext


    //
    // featuredHeadr should be displayed only on first page and should display only
    // last featured post.
    //
    let featuredHeader = () => {
        if (page === 1) {
            const { category, title, description, date } = group[0].node.frontmatter

            //
            // each category has a specific color associated with it
            //
            let categoryClass = () => {
                switch(category) {
                    case "News":
                        return "newsCategoryClass"
                    case "Interview":
                        return "interviewCategoryClass"
                    case "Email Marketing":
                        return "emailMarketingCaregoryClass"
                    case "Inside Poirot":
                        return "insidePoirotCategoryClass"
                    default:
                        return "unknownCategoryClass" 
                }
            }
            
            return (
                <div className={styles.insightsFeaturedContentWrapper}>
                    <div className="sectionWrapper1200">
                        <div key={group[0].id} className={styles.featuredContentCardWrapper}>
                            <Link to={group[0].node.fields.slug}>
                            <div className={styles.featuredContentCard}>
                                <div className={styles.featuredContentCardData}>
                                    <div className={categoryClass()}>{category}</div>
                                    <div><h1>{title}</h1></div>
                                    <div><h2>{description}</h2></div>
                                    <div><h4>{date}</h4></div>
                                </div>
                                <div className={styles.featuredContentCardImage}>
                                    <Img className={styles.featuredThumbnailImage} fluid={group[0].node.frontmatter.image.childImageSharp.fluid} /> 
                                </div>
                            </div>
                            </Link>
                        </div>
                    </div>
                </div>
            )
        } else {
            return 
        }
    }

    //
    // insights array is used to eliminate duplication of featured post on main page.
    // Featured post is alway positioned on index 0 as is defined in gatsby-node.js.
    //
    var insights
    var item = 0
    if (page === 1) {
        insights = group.slice(1)
    } else {
        insights = group
    }

    //
    // get index of the first and last page used for pagination and generate pagination index.
    //
    let pagination = () => {
        const totalNumberOfPages = groupedPosts.length

        const previousPage = () => {
            if (page === 1) {
                return
            } else {
                return (
                    <div className={styles.paginationCaret}>
                        <Link to={page > 2 ? `/insights/${page - 2}` : `/insights/`}>
                            <svg width="7px" height="10px" viewBox="0 0 7 10" version="1.1" xmlns="http://www.w3.org/2000/svg"><g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd"><g transform="translate(-712.000000, -4797.000000)" stroke="#343f44"><g transform="translate(713.000000, 4791.000000)"><polyline transform="translate(2.574990, 11.000000) rotate(-270.000000) translate(-2.574990, -11.000000) " points="-2.42500991 8.72497026 2.42500991 13.5749901 7.57499009 8.42500991"></polyline></g></g></g>
                            </svg>
                        </Link>
                    </div>
                )
            }
        }

        const firstPage = () => {
            if (totalNumberOfPages > 3 && page > 2) {
                if (page === 3) {
                    return (
                        <div className={styles.paginationIndex}><Link to={`/insights/`}>1</Link></div>
                    )
                } else {
                    return (
                        <div className={styles.paginationIndex}><Link to={`/insights/`}>1</Link><span>&nbsp;&nbsp;...</span></div>
                    ) 
                }
            } else {
                return
            }
        }

        const lastPage = () => {
            if (totalNumberOfPages - 2 > page) {
                return (
                    <div className={styles.paginationIndex}><span>...&nbsp;&nbsp;</span><Link to={`/insights/${totalNumberOfPages - 1}`}>{totalNumberOfPages}</Link></div>
                )
            } else if (totalNumberOfPages - 2 === page) {
                return (
                    <div className={styles.paginationIndex}><Link to={`/insights/${totalNumberOfPages - 1}`}>{totalNumberOfPages}</Link></div>
                )
            } else {
                return
            }
        }

        const nextPage = () => {
            if (page === totalNumberOfPages || totalNumberOfPages === 1) {
                return
            } else {
                return (
                    <div className={styles.paginationCaret}>
                        <Link to={`/insights/${page}`}>
                            <svg width="7px" height="10px" viewBox="0 0 7 10" version="1.1" xmlns="http://www.w3.org/2000/svg"><g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd"><g transform="translate(-822.000000, -4797.000000)" stroke="#343f44"><g transform="translate(713.000000, 4791.000000)"><polyline transform="translate(112.574990, 11.000000) rotate(-90.000000) translate(-112.574990, -11.000000) " points="107.57499 8.72497026 112.42501 13.5749901 117.57499 8.42500991"></polyline></g></g></g>
                            </svg>
                        </Link>
                    </div>
                )
            }
        }

        const multiplePages = () => {
            var pagesIndex = []

            // determine page position from left & right
            let leftDistance = page - 1
            let rightDistance = totalNumberOfPages - page

            // case where both left and right sides have more than 2 pages
            if (leftDistance >= 1 && rightDistance >= 1) {
                pagesIndex = [page - 1, page, page + 1]
            } 

            if (leftDistance < 1 && rightDistance === 1) {
                pagesIndex = [page, page + 1]
            }

            if (leftDistance < 1 && rightDistance > 1) {
                pagesIndex = [page, page + 1, page + 2]
            }

            if (leftDistance === 1 && rightDistance < 1) {
                pagesIndex = [page - 1, page]
            }

            if (leftDistance > 1 && rightDistance < 1) {
                pagesIndex = [page - 2, page - 1, page]
            }    

            return (
                <React.Fragment>
                    {
                        pagesIndex.map(function (index, i) {
                            if (index === page) {
                                return (
                                    <div key={i} className={styles.paginationIndex + ' ' + styles.currentPaginationIndex}>
                                        <Link to={index > 1 ? `/insights/${index - 1}` : `/insights/`}>{index}</Link>
                                    </div>
                                )
                            } else {
                                return(
                                    <div key={i} className={styles.paginationIndex}>
                                        <Link to={index > 1 ? `/insights/${index - 1}` : `/insights/`}>{index}</Link>
                                    </div>
                                )
                            }
                        })
                    }
                </React.Fragment>
            )
        }

        

        return (
            <div className={styles.pagePaginationWrapper}>
                <div className="sectionWrapper1200">
                    <div className={styles.pagePagination}>

                        {previousPage()}
                        {firstPage()}

                        {multiplePages()}

                        {lastPage()}
                        {nextPage()}

                    </div>
                </div>
            </div>
        )
    }


    return (
            
        <Layout isNavigationTransparent={false} isHomePage={false}>
            <SEO
                title="Poirot Insights"
                description="Instantly find anyone's email address. Backed by industry-leading email lookup & verification technology, Poirot helps your sales and lead generation efforts."
                keywords={[
                    `Poirot`,
                    `Poirot Features`,
                    `Email Lookup Tool`,
                    `Email Finder`,
                    `Email Checker`,
                ]}
            />

            <div className={styles.pageTitleBlockWrapper}>
                <div className="sectionWrapper1200">
                    <h1>Insights - Poirot's most recent news and updates</h1>
                </div>
            </div>
            {featuredHeader()}

            <div className={styles.insightsIndexBlockWrapper}>
                <div className="sectionWrapper1080">
                    <div className={styles.insightsIndex}>

                        {insights.map(({ node }) => {

                            //
                            // increase the item conunter from 0 by 1
                            //
                            item += 1

                            const { category, title, date, image } = node.frontmatter

                            //
                            // each category has a specific color associated with it
                            //
                            let categoryClass = () => {
                                switch(category) {
                                    case "News":
                                        return "newsCategoryClass"
                                    case "Interview":
                                        return "interviewCategoryClass"
                                    case "Email Marketing":
                                        return "emailMarketingCaregoryClass"
                                    case "Inside Poirot":
                                        return "insidePoirotCategoryClass"
                                    default:
                                        return "unknownCategoryClass"
                                }
                            }

                            //
                            // set required grid span class depending on page and index
                            //
                            let gridSpanClass = () => {
                                if (page ===1) {
                                    return (
                                        ((item >= 3 && item <= 5) || (item >= 10 && item <= 12) || (item >= 17 && item <= 19)) ? "gridSpanClassSmall" : "gridSpanClassLarge"
                                    )
                                } else {
                                    return (
                                        ((item >= 3 && item <= 5) || (item >= 8 && item <= 10) || (item >= 15 && item <= 20)) ? "gridSpanClassSmall" : "gridSpanClassLarge"
                                    )
                                }
                            }

                            return (
                                <Link key={node.id} to={node.fields.slug} className={styles.poirotInsightCard + ' ' + styles[gridSpanClass()]}>
                                    <div>
                                        <div className={styles.insightThumbnailImageWrapper}>
                                            <Img className={styles.insightThumbnailImage} fluid={image.childImageSharp.fluid} /> 
                                        </div>
                                        <div className={styles.poirotInsightCardData}>
                                            <div className={categoryClass()}>
                                                {category}
                                            </div>
                                            <div><h1>{title}</h1></div>
                                            <div><h4>{date}</h4></div>
                                        </div>
                                    </div>
                                </Link>
                            )
                        })}

                    </div>
                </div>
            </div>


            {pagination()}



        </Layout>
    )
}

export default BrowseBlogPosts